import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SecurityService } from '../services/security.service';
import { AppSettingsService } from '../services/app-settings.service';

@Injectable({
  providedIn: 'root',
})

export class HttpRequestInterceptor implements HttpInterceptor {

  token!:string;

  constructor(
    private jwtHelper: JwtHelperService, 
    private securityService: SecurityService,
    private appSettings: AppSettingsService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenJWT = this.jwtHelper.tokenGetter();
    const decode = this.jwtHelper.decodeToken(tokenJWT)
    this.securityService.saveTokenDecode(decode);
    const tokenShort =  this.securityService.getToken();
    this.token = tokenShort
    if (req.withCredentials) {
      if(req.url.includes(`${this.appSettings.getAdminApi()}`)){
        this.token = tokenJWT
      }
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + this.token,
          },
          withCredentials: false,
        });
      }
    // }
    return next.handle(req);
  }
}
