export const Permission = {
    pages: [
        {
            path: '/simulator',
            roles: ['Administrador', 'Gerencia', 'Gerência', 'Operador', 'Produtos', 'Operador Fast Moving']
        },
        {
            path: '/',
            roles: ['Administrador', 'Gerencia', 'Gerência', 'Operador', 'Produtos', 'Operador Fast Moving']
        },
        {
            path: '/policies',
            roles: ['Adminstrador Fast Moving', 'Administrador', 'Gerencia', 'Gerência', 'Produtos', 'Operador Fast Moving']
        },
        {
            path: '/policy-create-update',
            roles: ['Adminstrador Fast Moving', 'Administrador', 'Gerencia', 'Gerência', 'Produtos', 'Operador Fast Moving']
        },
        {
            path: '/settings/token',
            roles: ['Administrador Fast Moving', 'Administrador', 'Tecnologia', 'Produtos', 'Operador Fast Moving']
        },
        {
            path: '/settings/security',
            roles: ['Administrador', 'Gerencia', 'Gerência', 'Operador', 'Tecnologia', 'Produtos', 'Operador Fast Moving']
        },
        {
            path: '/settings/profile',
            roles: ['Administrador', 'Gerencia', 'Gerência', 'Operador', 'Tecnologia', 'Produtos', 'Operador Fast Moving']
        }

    ]
}