import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SidebarDirective } from '../../@onboarding/shared/sidebar/sidebar.directive';
import { SidenavService } from './sidenav/sidenav.service';
import { filter, map, startWith } from 'rxjs/operators';
import { ThemeService } from '../../@onboarding/services/theme.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@onboarding/utils/check-router-childs-data';
import { SecurityService } from 'src/@onboarding/services/security.service';

let flag = false;
@Component({
  selector: 'onboarding-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('configPanel', { static: true }) configPanel: SidebarDirective;
  sidenavOpen$ = this.sidenavService.open$;
  sidenavMode$ = this.sidenavService.mode$;
  sidenavCollapsed$ = this.sidenavService.collapsed$;
  sidenavExpanded$ = this.sidenavService.expanded$;
  progressPanelOpen: boolean;

  sideNavigation$ = this.themeService.config$.pipe(map((config) => config.navigation === 'side'));
  topNavigation$ = this.themeService.config$.pipe(map((config) => config.navigation === 'top'));
  toolbarVisible$ = this.themeService.config$.pipe(map((config) => config.toolbarVisible));
  toolbarPosition$ = this.themeService.config$.pipe(map((config) => config.toolbarPosition));
  footerPosition$ = this.themeService.config$.pipe(map((config) => config.footerPosition));

  scrollDisabled$ = this.router.events.pipe(
    filter<NavigationEnd>((event) => event instanceof NavigationEnd),
    // tslint:disable-next-line: deprecation
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, (data) => data.scrollDisabled)),
  );

  constructor(
    private sidenavService: SidenavService,
    private themeService: ThemeService,
    private router: Router,
    private securityService: SecurityService,

  ) { }

  private loadNavigation() {
    const navigationItems = [
      {
        name: 'Menu.Dashboard',
        routeOrFunction: '/',
        icon: 'dashboard',
        position: 10,
        pathMatchExact: true,
      },
      {
        name: 'Menu.Policies',
        routeOrFunction: '/policies',
        icon: 'rule',
        position: 20,
        pathMatchExact: true,
      },
      {
        name: 'Menu.Simulator',
        routeOrFunction: '/simulator',
        icon: 'play_arrow',
        position: 30,
        pathMatchExact: true,
      },
    ];

    const filteredNavigationItems = navigationItems.filter((item) =>
      this.securityService.hasPermissionForRoute(item.routeOrFunction));
    this.sidenavService.addItems(filteredNavigationItems);
  }

  ngOnInit() {
    this.loadNavigation();
  }

  closeProgressPanel() {
    this.progressPanelOpen = false;
  }

  openProgressPanel() {
    this.progressPanelOpen = true;
  }

  openConfigPanel() {
    this.configPanel.open();
  }

  closeSidenav() {
    this.sidenavService.close();
  }

  openSidenav() {
    this.sidenavService.open();

    toggle();

    function toggle() {
      if (flag === false) {
        toggleMyBlock(true);
      } else {
        toggleMyBlock(false);
      }
    }

    function toggleMyBlock(show) {
      flag = !flag;
      const toolbar = document.getElementById('toolbar');
      toolbar.style.display = show ? 'block' : 'none';
    }
  }

  ngOnDestroy(): void { }
}
