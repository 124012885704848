<div class="navigation-item-container">
  <a
    (click)="onClick()"
    class="navigation-item"
    fxLayout="row"
    fxLayoutAlign="start center"
    matRipple
    routerLinkActive="active"
  >
    <mat-icon class="icon">{{ item.icon }}</mat-icon>
    <span class="name">{{ item.name | translate }}</span>
  </a>
</div>
