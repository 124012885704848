import { ActivatedRoute } from '@angular/router';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { Platform } from '@angular/cdk/platform';
import { SecurityService } from 'src/@onboarding/services/security.service';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { SplashScreenService } from 'src/@onboarding/services/splash-screen.service';
import { ThemeService } from 'src/@onboarding/services/theme.service';

@Component({
  selector: 'onboarding-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private sidenavService: SidenavService,
    private iconRegistry: MatIconRegistry,
    private renderer: Renderer2,
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private route: ActivatedRoute,
    public splashScreenService: SplashScreenService,
    private securityService: SecurityService,
  ) { }

  ngOnInit(): void {
    this.loadLayout();
    this.setDefaultLanguage();
    if (this.isRedirect()) {
      const accessToken = this.getAccessTokenFromUrl();
      if (accessToken != null) {
        this.securityService.setRedirectFromCcm();
        this.securityService.signin({
          access_token: accessToken,
        });        
      }
    }
  }

  private loadLayout() {
    this.setStyle();
    this.setIcons();
    this.setTheme();
    this.setBlink();
  }
  private setStyle() {
    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has('style')))
      .subscribe((queryParamMap) => this.themeService.setStyle(queryParamMap.get('style')));
  }

  private setIcons() {
    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
  }

  private setTheme() {
    this.themeService.theme$.subscribe((theme) => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);
      }
      this.renderer.addClass(this.document.body, theme[1]);
    });
  }

  private setBlink() {
    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }
  }

  private setDefaultLanguage() {
    const language = this.securityService.getLanguageAlias();
    if (language) {
      this.securityService.setLanguageDefault(language);
    } else {
      this.securityService.setLanguageDefault('pt');
    }
  }

  private isRedirect() {
    return window.location.href.indexOf('?token=') !== -1;
  }

  private getAccessTokenFromUrl() {
    const urlValues = window.location.href.split('?token=');
    return urlValues[1];
  }
}
