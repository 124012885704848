import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from './security.service';
import { Permission } from '../constant/Permission';

@Injectable({
  providedIn: 'root',
})
export class HasRoleGuardService implements CanActivate {
  constructor(
    private router: Router,
    private securityService: SecurityService,
  ) { }

  role!: string
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = state.url
    if (!this.securityService.hasPermissionForRoute(url)) {
      this.getAccessibleRoute(url)
      return false
    }
      return true;
  }

  private getAccessibleRoute(url: string) {
    const getRoleUser = this.securityService.getRoleUser();
    for (const permission of Permission.pages) {
      if (permission.roles.includes(getRoleUser) && url === '/') {
        this.router.navigate(['/settings/profile'])
      }
    }
  }

}
